<template>
    <div id="RolesPermisos">
        <b-container>
            <b-card v-for="(item, index) in goodTable" :key="index">
                <template #header>
                    <h2 class="mb-0 mr-2">{{ item.titulo }}</h2>
                    <div class="d-flex align-items-center mr-auto" v-if="item.btn">
                        <b-button class="text-nowrap d-flex align-items-center" size="md" :variant="item.btn.variant"
                            @click="openModal(item.btn.modal)">
                            <span class="align-middle">{{ item.btn.texto }}</span>
                        </b-button>
                    </div>
                </template>
                <b-table :items="users" :fields="item.tabla.columns" responsive :busy="isLoading"
                    empty-text="No hay usuarios registrados por el momento">
                    <!-- Custom content while loading -->
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner small></b-spinner>
                            <strong> Cargando datos... </strong>
                        </div>
                    </template>

                    <!-- Columna de la foto -->
                    <template #cell(foto_persona)="row">
                        <b-avatar variant="light">
                            <b-avatar size="32" :src="row.value" variant="light-primary" />
                        </b-avatar>
                    </template>

                    <!-- Columna del nombre -->
                    <template #cell(primer_nombre)="row">
                        <div v-b-tooltip.hover class="title-tab" :title="row.value">
                            {{ row.value }}
                        </div>
                    </template>

                    <!-- Columna de roles -->
                    <template #cell(roles)="row">
                        <div v-b-tooltip.hover class="title-tab" :title="row.value.nombre">
                            {{ row.value.nombre }}
                        </div>
                    </template>

                    <!-- Columna de acciones -->
                    <template #cell(actions)="row">
                        <b-badge :style="{ margin: '5px' }" variant="light-primary" v-for="item in row.value"
                            :key="item.id">
                            {{ item.title }}
                        </b-badge>
                    </template>

                    <!-- Columna de género -->
                    <template #cell(genero)="row">
                        <b-badge :variant="row.value.id == 2 ? 'light-info' : 'light-danger'">{{ row.value.name
                            }}</b-badge>
                    </template>

                    <!-- Columna de settings (botones) -->
                    <template #cell(settings)="row">
                        <b-button-group>
                            <b-button v-b-tooltip.hover.left pill :title="btn.texto" :variant="btn.variant"
                                @click="openModal(btn.modal, row.item)" :key="index"
                                class="btn-icon d-flex justify-content-center align-items-center"
                                v-for="(btn, index) in item.config.btn">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                    :style="{ width: '1.25em', height: '1em', margin: '-0.5em', fontSize: '14px' }"
                                    fill="currentColor">
                                    <path
                                        d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z" />
                                </svg>
                            </b-button>
                        </b-button-group>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
                            @click="delUser(row.item.id)" class="btn-icon rounded-circle">
                            <feather-icon icon="ArchiveIcon" />
                        </b-button>
                    </template>
                </b-table>

            </b-card>
        </b-container>
        <transition name="fade">
            <v-modal v-if="modal.state" @close="modal.state = false" :titulo="modal.titulo" :size="modal.size"
                :footer="modal.footer">
                <component :is="modal.is" v-bind="modal.prop" ref="modal" />
                <template #footer>
                    <b-overlay :show="overlay">
                        <b-button @click="onSubmit" variant="success" :disabled="overlay">{{ modal.prop.row ? 'Guardar'
                            :
                            'Crear' }}</b-button>
                    </b-overlay>
                </template>
            </v-modal>
        </transition>
    </div>
</template>

<script>
import mixinApis from '@/views/componente/mixinApis'
import VModal from "@/views/componente/vModal"
import FormUser from '@/views/apps/user/users-list/FormUser'
import Ripple from 'vue-ripple-directive'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'UserList',
    mixins: [mixinApis],
    components: {
        VModal,
        FormUser
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            overlay: false,
            isLoading: false,
            modal: {
                state: false,
            },
        }
    },
    computed: {
        ...mapState('appUser', ['users']),
        goodTable() {
            return [
                {
                    titulo: 'Agentes',
                    btn: {
                        texto: 'Crear Agente',
                        variant: 'info',
                        modal: {
                            titulo: 'Crear Agente',
                            is: 'FormUser',
                        }
                    },
                    config: {
                        prop: '',
                        btn: [{
                            variant: 'success',
                            texto: 'Editar Agente',
                            modal: {
                                titulo: 'Editar Agente',
                                is: 'FormUser',
                            }
                        }],
                    },
                    tabla: {
                        columns: [
                            { key: "id", label: "ID" },
                            { key: "foto_persona", label: "Foto" },
                            { key: "primer_nombre", label: "Agente" },
                            { key: "email", label: "Email" },
                            { key: "genero", label: "Género" },
                            { key: "roles", label: "Rol" },
                            { key: "settings", label: "Ajustes" },
                        ],
                        rows: this.users,
                    }
                }
            ]
        }
    },
    methods: {
        ...mapActions('appUser', ['getUsers', 'getRoles', 'deleteUser']),
        onSubmit() {
            this.overlay = true;
            this.$refs['modal'].onSubmit()
                .then(state => {
                    this.modal.state = !state;
                })
                .finally(() => {
                    this.overlay = false;
                });
        },
        openModal(modal, row) {
            if (!modal.footer) modal.footer = true;
            if (!modal.prop) modal.prop = {};
            if (row) modal.prop.row = row;
            Object.assign(this.modal, { state: true }, modal);
        },


        delUser(id) {
            const code = id;
            this.$swal({
                title: 'Esta seguro?',
                text: "No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, elimínelo!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.isConfirmed) {
                    this.deleteUser(code).then((response) => {

                        this.$swal({
                            icon: 'success',
                            title: 'Eliminado!',
                            text: response.data.message,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            }
                        })
                    })
                }
            })
        },

    },
    mounted() {
        this.isLoading = true
        this.getUsers()
        setTimeout(() => {
            this.isLoading = false;

        }, 2000)
        this.getRoles()
    },
}
</script>
